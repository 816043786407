import React from "react"
import { handleAuthentication } from "../../utils/auth"

const Callback = () => {
  handleAuthentication()
  return (
    <div className="my-5 w-100 text-center">
      <img src="/images/loader.svg" alt="loading" />
    </div>
  )
}

export default Callback
